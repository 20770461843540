import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import { useHistory } from "react-router-dom";
import '../App.css'

// const background_url='https://images.pexels.com/photos/2226458/pexels-photo-2226458.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
const background_url = "https://images.pexels.com/photos/262353/pexels-photo-262353.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"

function Homepage() {
  const history = useHistory();
  const [email, setEmail] = useState(null); 
  const [password, setPassword] = useState(null); 
  const [error, setError] = useState(null);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      history.push({
        pathname:  "/dashboard",
     });
    }
  });

  function handleSignIn(e){
    e.preventDefault();
   
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() => {
      history.push({
        pathname:  "/dashboard",
     });

    })
    .catch((error) => {
      var errorMessage = error.message;
      setError(errorMessage);
    });
  }

  function handleForgotPassword(){   
    firebase.auth().sendPasswordResetEmail('contact@1948mg.com')
    .then(() => {
      alert('Password reset email sent to Admin.')
    })
    .catch((error) => {
      var errorMessage = error.message;
      setError(errorMessage);
    });
  }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', backgroundColor:'whitesmoke'  }}>
        <div style={jumboDiv}>
        </div>

        <div style={{display: 'flex', color: 'white', flexDirection: 'column', justifyContent: "center", alignItems: 'center', position: 'relative',width: '60%', height: '100vh', backgroundColor:'whitesmoke' }}>
          <h1 style={{fontSize:'48px', textAlign:'center', color: 'black', fontStyle:"italic"}}>Quality freight &amp;	<br></br>small cargo delivery</h1>
          <h2 style={{color: 'black'}}>1948 MG 📦</h2>
          <form 
            onSubmit={handleSignIn}
            style={{display: 'flex', flexDirection: 'column', justifyContent: "center"}}>
            <input 
              id={'email'} type={'email'} 
              onChange={(event)=> {setEmail(event.target.value)}} 
              style={{borderRadius: '4px', outline:'none', paddingLeft:'10px', height: '60px', width: '290px', fontSize: '16px', marginBottom: '20px'}} placeholder={'Email'}/>
            <input 
              id={'password'} type={'password'} 
              onChange={(event)=> {setPassword(event.target.value)}}
              style={{borderRadius: '5px', outline:'none', paddingLeft:'10px', height: '60px', width: '290px', fontSize: '16px', marginBottom: '20px'}} placeholder={'password'}/>
            <button 
              className={'large-button'}
            >
                Login
            </button>
          </form>
          <p onClick={() => handleForgotPassword()} style={{fontSize:'14px', color:'black', cursor:'pointer' }}>Forgot Password?</p>
          <p style={{fontSize:'14px', color:'red', }}>{error}</p>

          <div style={{position:'fixed', bottom:20, color:'black', width: '50%',display: 'flex', justifyContent: "space-around", alignItems: 'center', }}>
            <div>
              <p>1948 Management Group, LLC</p>
            </div>
            <div>
              <p style={{fontStyle: 'italic'}}>San Francisco Bay Area, CA USA</p>

            </div>
            <div>
            <a href="mailto:contact@1948mg.com" style={{textDecoration: 'none', color: 'gray'}}>contact@1948mg.com</a>  
            </div>
          </div>
        </div>
       
      </div>

      {/* <div style={{display: 'flex', justifyContent: "space-around", alignItems: 'center', position: 'relative', height: '10vh', color: 'gray', backgroundColor: 'black'}}>
        <p>1948 Management Group, LLC</p>
        <button onClick={()=>{alert('Email contact@1948mg.com with subject line "Hiring".')}} style={{outline:'none', cursor:'pointer', backgroundColor: 'whitesmoke', color:'black', fontWeight: 'bold', borderRadius:'5px', padding:"8px"}}>We're Hiring! 🎉 </button>
        <a href="mailto:contact@1948mg.com" style={{textDecoration: 'none', color: 'gray'}}>contact@1948mg.com</a>  
      </div> */}
    </>
  );
}

export default Homepage;


const jumboDiv = {
  display: 'flex',
  color: 'white',
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: 'center',
  position: 'relative',
  width: '60%',
  height: '100vh', 
  backgroundColor:'whitesmoke',
  backgroundImage: `url(${background_url})`,
  borderRadius: '0px 20px 20px 0px',
}