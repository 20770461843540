import React from "react";
import '../../App.css'
import firebase from '../../firebase'

const firestore = firebase.firestore();


function SnapshotCard(props) {

  function handleDelete() {

    let message = `Are you sure you want to delete ${props.title}?`
    let result = window.confirm(message);

    if(result) {
      firestore.collection("snapshots").doc(props.card_id).delete();
    }
  }

  return (
    <>
    <div style={{display: 'flex', flexDirection:"row", justifyContent: 'center', alignItems: 'center'}}>

   
      <div className={'snapshot-card'}>
        <a className={'black-link'} href={props.link} target="_blank" rel="noreferrer">{props.title}</a>
      </div>

      <i 
        onClick={handleDelete}
        className="fa fa-trash red cursor f18 black" 
        aria-hidden="true">
      </i>

     </div> 

    </>
  );
}

export default SnapshotCard;
