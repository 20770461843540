// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app'

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

firebase.initializeApp({
    apiKey: "AIzaSyAqYgjbpGKtD8Gxf0cQ2J1r28kzURKCb2U",
    authDomain: "management-group-1948.firebaseapp.com",
    projectId: "management-group-1948",
    storageBucket: "management-group-1948.appspot.com",
    messagingSenderId: "1037418484891",
    appId: "1:1037418484891:web:27d03bd671c9e36475e23e",
    measurementId: "G-MZNE0YHV21"
})

// These are callable as firestore as needed.
// export const auth = firebase.auth();
export const firestore = firebase.firestore();
// export const functions = firebase.functions();
// export const storage = firebase.storage();
export default firebase;
