import React from "react";
import { Link } from "react-router-dom";
import '../App.css'


function Navigation() {
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', }}>
        <div style={{display: 'flex', justifyContent: "center", alignItems: 'center', position: 'relative', width: '25%', height: '10vh',  color: 'black', backgroundColor: 'whitesmoke'}}> 
          <Link to={'/dashboard'} style={{textDecoration: 'none', color: 'black', fontWeight:'bold', fontSize: '20px'}}>@1948mg</Link>
        </div>

        <div style={{display: 'flex',  justifyContent: "flex-end", alignItems: 'center',  width: '75%', height: '10vh', color: 'black', backgroundColor: 'whitesmoke'}}> 
            < Link to={'/settings'} className="round-button">Settings</Link>
        </div>
      </div>     
    </>
  );
}

export default Navigation;



