import React from "react";
import Navigation from '../components/Navigation'
import '../App.css'
import SnapshotContainer from "../components/Snapshot/SnapshotContainer";
import SnapshotCard from "../components/Snapshot/SnapshotCard";
import firebase from '../firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import Homepage from "./Homepage";

const firestore = firebase.firestore();

function Snapshot() {
  var user = firebase.auth().currentUser;
  var uid = (user) ? user.uid : null;
  const snapshotRef = firestore.collection('snapshots');
  var query = snapshotRef.where('uid', '==',  uid);
  const [snapshots] = useCollectionData(query, { idField: 'id' });  

  if (firebase.auth().currentUser) {
    return (
      <>
        <Navigation />
        <SnapshotContainer>
            {snapshots && snapshots.map(msg => <SnapshotCard link={msg.link} title={msg.title} card_id={msg.card_id}/>)}
        </SnapshotContainer>
      </>
    );
  } else {
    return <Homepage />
  }
}

export default Snapshot;
