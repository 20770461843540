import React, {useState} from "react";
import firebase from '../../firebase';
import { nanoid } from 'nanoid'
import {useAuthState} from 'react-firebase-hooks/auth'

import '../../App.css'

const auth = firebase.auth();
const firestore = firebase.firestore();


function SnapshotContainer(props) {
  const [link, setLink] = useState(null);
  const [title, setTitle] = useState(null);
  const [user] = useAuthState(auth);


  function handleCreateCard(e) {
    e.preventDefault()
    var card_id = nanoid();

    if (user) {
      firestore.collection("snapshots").doc(card_id)
      .set({
        uid: firebase.auth().currentUser.uid,
        link: link,
        title: title, 
        card_id: card_id,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function(docRef) {
        setLink(null);
        setTitle(null);
        document.getElementById('Form-Link').value='';
        document.getElementById('text-link').value='';
        document.getElementById('text-title').value='';

        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function(error) {
          console.error("Error adding document: ", error);
      });
    }
  }

  function selectText(e) {    
    const input = document.getElementById(e.target.id);
    input.focus();
    input.select();
  }

  return (
    <>
      <div style={{display: 'flex', minHeight: '90vh', flexWrap: 'wrap',  height:'100%', backgroundColor:'#F5F5F5', paddingBottom:"5%"}}>
        <div style={{ width: '100%', height: '80px', display:'flex', justifyContent: 'center',}}>
          
          <form 
            id={'Form-Link'}
            onSubmit={handleCreateCard }
            style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
            
            <input 
              onClick={selectText}
              id="text-link"
              type={'text'}
              onChange={(e)=>setLink(e.target.value) }
              style={{height: '50px', paddingLeft: "10px", borderRadius:"10px", width:'250px', fontSize:"18px", paddingRight: "10px", marginRight: '5px'}} 
              placeholder={"https://www.example.com"}/>
            <input  
              onClick={selectText}
              id="text-title"
              type={'text'}
              onChange={(e)=>setTitle(e.target.value) }
              style={{height: '50px', paddingLeft: "10px", width:'150px', borderRadius:"10px", fontSize:"18px", marginRight: '5px'}} 
              placeholder={"Title"}/>
            <button disabled={(!link || !title)}
             className={'create-card-button'}>Create Card</button>
          </form>

        </div>
       
         {props.children}


       
      </div>

    </>
  );
}

export default SnapshotContainer;
