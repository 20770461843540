import React, {useState} from "react";
import Navigation from '../components/Navigation'
import firebase from '../firebase';
import {useHistory} from 'react-router-dom'
import '../App.css'
import SettingsContainer from "../components/Settings/SettingsContainer";
import Homepage from "./Homepage";

function Settings() {
  const history = useHistory();
  const [error, setError] = useState(null);

  function handleLogout(e){
    e.preventDefault(); 

    firebase.auth().signOut().then(() => {
      history.push('/')
    }).catch((error) => {
      setError(error)
    });
    
  }


  if (firebase.auth().currentUser) {
    return (
      <>
        <Navigation />
        <SettingsContainer>
          <div className={'settings-card'}>
            <h5>1948 Mangagment Group, LLC</h5>
            <button className={'large-button'} onClick={handleLogout}>Logout</button>
            <p style={{color: 'red'}}>{error}</p>
          </div>
        
        </SettingsContainer>
      </>
    );
  } else {
    return <Homepage />
  }
}

export default Settings;
