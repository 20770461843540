import React from "react";
import '../../App.css'

function SettingsContainer(props) {
  return (
    <>
      <div style={{display: 'flex', justifyContent:'center', alignItems:'center',  height:'90vh', backgroundColor:'whitesmoke'}}>
        {props.children}
      </div>

    </>
  );
}

export default SettingsContainer;
