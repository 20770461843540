import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Homepage from './views/Homepage'
import Dashboard from './views/Dashboard'
import Settings from './views/Settings'
import { nanoid } from "nanoid";
import firebase from './firebase'
import { useAuthState } from 'react-firebase-hooks/auth';


const auth = firebase.auth()



function App() {
  useAuthState(auth); 

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/settings' render={(props) => <Settings {...props} key={nanoid()}/>}/>
        <Route exact path='/dashboard' render={(props) => <Dashboard {...props} key={nanoid()}/>}/>
        <Route exact path='/'render={(props) => <Homepage {...props} key={nanoid()}/>}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

